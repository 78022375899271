import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "./HighchartsMixins";

import "./figure.scss";
import { useState } from "react";
import setInitialOptions from "./setInitialFigureOptions";
import initialOptions from "./initialToggleOptions";
import Header from "./graphComponents/Header";
import Graph from "./graphComponents/Graph";
import Summary from "./graphComponents/Summary";
import TogglePane from "./graphComponents/TogglePane";
import { DATA_SET_CHANGE } from "../../../../redux/actions/graphs";
import HSAMap from "./HSAMap";
import StateMap from "./StateMap";
import CountyMap from "./CountyMap";
import { useEffect } from "react";

const Toggle = ({ serverdata, size, slug }) => {
  const dispatch = useDispatch();

  const data = serverdata;

  const toggleValues = Array.from(new Set(data.toggle.map((x) => x.toggle)));

  let iTog = toggleValues[0];

  if (data.figureJson) {
    try {
      const figJson = JSON.parse(data.figureJson);
      if (figJson.defaultToggle) {
        iTog = figJson.defaultToggle;
      }
    } catch {
      console.warn("tried to parse non-json json");
    }
  }

  const selection = useSelector((state) => state.graphState[data.figureNumber]);
  const [toggle, setToggle] = useState(
    selection && selection.toggle ? selection.toggle : iTog
  );

  const [options, setOptions] = useState();

  useEffect(() => {
    let newOptions = {
      title: {
        text: toggle,
      },
    };
    newOptions = setInitialOptions(
      initialOptions(),
      data,
      { subtitle: toggle },
      size,
      null,
      toggle
    );
    setOptions(newOptions);
  }, [data, size, toggle]);

  const toggleDataSet = (newToggle) => {
    setToggle(newToggle);
    dispatch({
      type: DATA_SET_CHANGE,
      payload: {
        figureNumber: data.figureNumber,
        toggle: newToggle,
      },
    });
  };

  let graph = <Graph highcharts={Highcharts} options={options} size={size} />;
  if (data.chartType === "HSA MAP") {
    graph = <HSAMap data={data} size={size} toggle={toggle} />;
  } else if (data.chartType === "STATE MAP") {
    graph = <StateMap data={data} size={size} toggle={toggle} />;
  } else if (data.chartType === "COUNTY MAP") {
  graph = <CountyMap data={data} size={size} toggle={toggle} />;
}

  return (
    <>
      {size !== "micro" ? (
        <>
          <Header figureData={data} currentToggle={toggle} slug={slug} />
          <TogglePane
            figureData={data}
            toggle={toggle}
            toggleValues={toggleValues}
            toggleDataSet={toggleDataSet}
            size={size}
          />
        </>
      ) : (
        <></>
      )}
      {options ? graph : <></>}
      <Summary figureData={data} size={size} />
    </>
  );
};

export default Toggle;
