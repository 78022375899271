import React from "react";

import config from "../../../config/config";

import "./ReferenceTables.scss";

const ReferenceTables2024 = () => {

  return (
    <>
      <h1>Reference Tables</h1>
      <section className="ref-tables-section">
        <ul>
          <li>
            <span className="ref-tables-volume">Volume 1 - CKD:</span>
            <ul>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=CKD_Ref_B_Prevalence_2024`}
                >
                  B. Prevalence
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=CKD_Ref_K_Expenditures_2024`}
                >
                  K. Expenditures
                </a>
              </li>
               <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=CKD_Ref_O_Drug_2024`}
                >
                  O. Drug
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span className="ref-tables-volume">Volume 2 - ESRD:</span>
            <ul>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_A_Incidence_2024`}
                >
                  A. Incidence
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_B_Prevalence_2024`}
                >
                  B. Prevalence
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_C_PatientChars_2024`}
                >
                  C. Patient Characteristics
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_D_Modality_2024`}
                >
                  D. Treatment Modalities
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_E_TransplantProcess_2024`}
                >
                  E. Transplantation: Process
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_F_TransplantOutcomes_2024`}
                >
                  F. Transplantation: Outcomes
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_G_Hospitalization_2024`}
                >
                  G. Morbidity and Hospitalization
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_H_Mortality_2024`}
                >
                  H. Mortality and Causes of Death
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_I_Survival_2024`}
                >
                  I. Patient Survival
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_J_Providers_2024`}
                >
                  J. Provider Characteristics
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_K_Costs_2024_all`}
                >
                  K. Expenditures for ESRD Patients (All)
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_K_Costs_2024_DualEligible`}
                >
                  K.S1. Expenditures for ESRD Patients (Dual Eligible)
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_K_Costs_2024_MedicareOnly`}
                >
                  K.S2. Expenditures for ESRD Patients (Medicare Only)
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_L_VascularAccess_2024`}
                >
                  L. Vascular Access
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_M_Census_2024`}
                >
                  M. Census Populations
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_N_International_2024`}
                >
                  N. International Comparisons
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=ESRD_Ref_O_Drug_2024`}
                >
                  O. Drug
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2024&referenceTable=Reference_Table_Methods`}
                >
                  Analytical Methods
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </section>
    </>
  );
};

export default ReferenceTables2024;
