import React from "react";
import { useParams } from "react-router";

import config from "../../../config/config";
import "./AnalyticalMethods.scss";

const ESRDAnalyticalMethods2024 = () => {
  const { year } = useParams();

  const download = (refNum) => {
    const url = `${config.baseUri}/analyticalMethods?year=${year}&fileName=${refNum}&volumeNumber=2`;
    window.location = url;
  };

  return (
    <div>
      <h1>Analytical Methods</h1>
      <ul>
        <li>
          <button onClick={() => download("Volume 2 Methods")}>
            Data Sources and Methods for ESRD Volume
          </button>
        </li>
        <li
          onClick={() => download("Codes_for_Cause_of_Hospitalization___ESRD_Volume_2024")}>
          <button>Codes for Cause of Hospitalization – ESRD Volume</button>
        </li>
        <li onClick={() => download("Cause_of_Death___ESRD_Volume_2024")}>
          <button>Codes for Cause of Death – ESRD Volume</button>
        </li>
        <li
          onClick={() => download("Codes_and_Algorithm_for_Common_CVDs_2024")}>
          <button>Codes and Algorithm for CVD and Procedure</button>
        </li>
        <li
          onClick={() => download("Cause_of_ESRD_2024")}>
          <button>Codes for Cause of ESRD</button>
        </li>
        <li
          onClick={() => download("DRG_Codes_For_Type_of_Hospitalization_2024")}>
          <button>DRG Codes for Type of Hospitalization</button>
        </li>
        <li>
          <button onClick={() => download("Codes_for_Prescription_Drug_Classes_2024")}>
            Codes for Prescription Drug Classes
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ESRDAnalyticalMethods2024;
