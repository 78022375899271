import React from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { GET_OUTLINE } from "../../../queries/outlineData";
import DOMPurify from "dompurify";

const Introduction_Text = () => {
  const { data } = useQuery(GET_OUTLINE);
  // match up current path with what sections are currently available
  const {
    params: { volume },
  } = useRouteMatch("/:year/:volume");

  const selectedVolume =
    data && data.volumes && volume
      ? data.volumes.filter((vol) => vol.slug === volume)[0]
      : null;

  return selectedVolume ? (
  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedVolume.text,
      {
        ADD_ATTR: ["target"],
      }) 
    }} />
  ) : (
    <></>
  );
  
};

export default Introduction_Text;
